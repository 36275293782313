import { Container, CssBaseline } from "@mui/material";
import { RecoilRoot } from "recoil";
import { Redirect, Route, Switch } from "wouter";
import DataHandler from "../../components/DataHandler/DataHandler";
import Header from "../../components/Header/Header";
import Dinner from "../Dinner/Dinner";
import DinnerSuccess from "../DinnerSuccess/DinnerSuccess";
import DonationForm from "../DonationForm/DonationForm";
import DonationList from "../DonationList/DonationList";
import DonationSuccess from "../DonationSuccess/DonationSuccess";
import DonationTypes from "../DonationTypes/DonationTypes";

const App = () => {
  return (
    <RecoilRoot>
      <Container maxWidth="lg">
        <CssBaseline />
        <Header />

        <DataHandler>
          <Switch>
            <Route path="/type/:type">
              {(params) => <DonationList expectedType={params.type} />}
            </Route>
            <Route path="/dona/:code">
              {(params) => <DonationForm code={params.code} />}
            </Route>
            <Route path="/thank-you">
              <DonationSuccess />
            </Route>
            <Route path="/cena/thank-you">
              <DinnerSuccess />
            </Route>
            <Route path="/cena">
              <Dinner />
            </Route>
            <Route path="/">
              <DonationTypes />
            </Route>
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </DataHandler>
      </Container>
    </RecoilRoot>
  );
};

export default App;
