import { DonationConfig } from "./dataManager";

export enum DonationMode {
  Recurring = "recurring",
  OneOff = "single",
  Custom = "arbitrary",
  Dinner = "dinner",
}

export enum DonorType {
  Individual = "individuo",
  Company = "azienda",
}

export enum PaymentMethod {
  Stripe = "card",
  BankTransfer = "bank",
}

export const getDonationTypeTitle = (type: DonationMode) => {
  if (type === DonationMode.Recurring) return "Donazione Continuativa";
  if (type === DonationMode.OneOff) return "Donazione Singola";
  if (type === DonationMode.Custom) return "Donazione Libera";
};

export const getDonationAmount = (donation: DonationConfig) => {
  let amount =
    +donation.amount > 0 ? `${donation.amount} €` : "Donazione libera";

  if (donation.type === DonationMode.Recurring) {
    amount += " al mese";
  }

  return {
    value: +donation.amount > 0 ? +donation.amount : null,
    label: amount,
  };
};
