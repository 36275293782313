import {
  Alert,
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  NativeSelect,
  Snackbar,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import countries from "world_countries_lists/data/countries/it/countries.json";
import { useLocation } from "wouter";
import StripePayment from "../../components/StripePayment/StripePayment";
import dataManager, { DinnerPaymentData } from "../../model/dataManager";
import { DonationMode, DonorType } from "../../model/donationUtils";
import { configState, isAdminState, isPayingState } from "../../model/store";

enum PaymentMethod {
  Stripe = "stripe",
  Cash = "cash",
  Bank = "bank",
  Cheque = "cheque",
}

const DinnerBookingForm = () => {
  const config = useRecoilValue(configState);
  const [isPaying, setIsPaying] = useRecoilState(isPayingState);
  const [isAdmin] = useRecoilState(isAdminState);

  const [, setLocation] = useLocation();

  const [error, setError] = useState<string | null>(null);
  const [bookerName, setBookerName] = useState("");
  const [email, setEmail] = useState("");
  const [numPlaces, setNumPlaces] = useState("");
  const [participantNames, setParticipantNames] = useState<string[]>([]);
  const [extraDonation, setExtraDonation] = useState("");
  const [comments, setComments] = useState("");
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
    PaymentMethod.Stripe
  );
  const [receipt, setReceipt] = useState(false);
  const [donorType, setDonorType] = useState<DonorType | null>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("IT");
  const [taxCode, setTaxCode] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");

  const [manualBookingLoading, setManualBookingLoading] = useState(false);

  const calculatedAmount = config.dinner.amount * +numPlaces + +extraDonation;

  const bookingData: DinnerPaymentData = {
    name: bookerName,
    places: +numPlaces,
    participants: participantNames,
    extraDonation: +extraDonation,
    comments,
    amount: calculatedAmount,
    receipt,
    donorType: donorType ?? undefined,
    firstName,
    lastName,
    companyName,
    email,
    phone,
    address: streetAddress,
    city,
    postcode: postCode,
    country,
    taxcode: taxCode,
    paymentMethod,
  };

  const setParticipantName = (idx: number, name: string) => {
    const newNames = [...participantNames];
    newNames[idx] = name;
    setParticipantNames(newNames);
  };

  const toggleReceipt = () => {
    setReceipt(!receipt);
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setStreetAddress("");
    setCity("");
    setPostCode("");
    setCountry("IT");
    setTaxCode("");
    setPhone("");
    setCompanyName("");
  };

  const clearAll = () => {
    setBookerName("");
    setEmail("");
    setNumPlaces("");
    setParticipantNames([]);
    setExtraDonation("");
    setComments("");
    setReceipt(false);
    setDonorType(null);
    clearForm();
  };

  const handleDonorType = (value: DonorType | null) => {
    setDonorType(value);
    clearForm();
  };

  const isFormComplete = () => {
    if (!isAdmin && !bookerName) {
      return false;
    }

    if (!isAdmin && !email) {
      return false;
    }

    if (+numPlaces < 1) {
      return false;
    }

    if (
      participantNames.filter((name) => name.trim().length > 0).length <
      +numPlaces
    ) {
      return false;
    }

    if (receipt) {
      if (!donorType) {
        return false;
      }

      if (donorType === DonorType.Individual) {
        if (
          firstName.trim().length === 0 ||
          lastName.trim().length === 0 ||
          streetAddress.trim().length === 0 ||
          city.trim().length === 0 ||
          postCode.trim().length === 0 ||
          country.trim().length === 0 ||
          taxCode.trim().length === 0
        ) {
          return false;
        }
      }
      if (donorType === DonorType.Company) {
        if (
          companyName.trim().length === 0 ||
          streetAddress.trim().length === 0 ||
          city.trim().length === 0 ||
          postCode.trim().length === 0 ||
          country.trim().length === 0 ||
          taxCode.trim().length === 0
        ) {
          return false;
        }
      }
    }

    return true;
  };

  const handleManualBooking = async () => {
    setManualBookingLoading(true);
    try {
      const { id, type } = await dataManager.createManualBooking(bookingData);
      setLocation(`/cena/thank-you?id=${id}&type=${type}`);
    } catch (err) {
      setError(
        (err as Error).message ??
          "Si è verificato un errore inaspettato durante il pagamento"
      );
    }
    setManualBookingLoading(false);
  };

  let participantFields;
  if (+numPlaces > 0) {
    participantFields = new Array(+numPlaces).fill("").map((_, idx) => (
      <Grid key={idx} item xs={12}>
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} sm={idx === 0 ? 8 : 12}>
            <TextField
              fullWidth
              variant="standard"
              label={`Partecipante #${idx + 1}`}
              value={participantNames[idx] || ""}
              placeholder="Nome e cognome"
              onChange={(evt) =>
                setParticipantName(idx, evt.currentTarget.value)
              }
              disabled={isPaying}
            />
          </Grid>
          {idx === 0 ? (
            <Grid key={`${idx}-btn`} item xs={12} sm={4}>
              <Button
                variant="text"
                onClick={() => setParticipantName(0, bookerName)}
                disabled={isPaying}
              >
                Copia nome di chi prenota
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    ));
  }

  const individualFields = (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Nome"
          value={firstName}
          onChange={(evt) => setFirstName(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Cognome"
          value={lastName}
          onChange={(evt) => setLastName(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Indirizzo"
          value={streetAddress}
          onChange={(evt) => setStreetAddress(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          variant="standard"
          label="Città"
          value={city}
          onChange={(evt) => setCity(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          variant="standard"
          label="CAP"
          value={postCode}
          onChange={(evt) => setPostCode(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          fullWidth
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.name}
          value={
            countries.find((c) => c.alpha2 === country.toLowerCase()) ?? null
          }
          onChange={(_, newValue) =>
            setCountry(newValue?.alpha2.toUpperCase() ?? "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Nazione"
              inputProps={{ ...params.inputProps }}
            />
          )}
          disabled={isPaying}
        />
      </Grid>
      <Grid item sm={6}></Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Codice Fiscale"
          value={taxCode}
          onChange={(evt) => setTaxCode(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Numero di Telefono"
          helperText="Opzionale"
          value={phone}
          onChange={(evt) => setPhone(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
    </>
  );

  const companyFields = (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Ragione Sociale"
          value={companyName}
          onChange={(evt) => setCompanyName(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item sm={6}></Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Indirizzo"
          value={streetAddress}
          onChange={(evt) => setStreetAddress(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          variant="standard"
          label="Città"
          value={city}
          onChange={(evt) => setCity(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          variant="standard"
          label="CAP"
          value={postCode}
          onChange={(evt) => setPostCode(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          fullWidth
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.name}
          value={
            countries.find((c) => c.alpha2 === country.toLowerCase()) ?? null
          }
          onChange={(_, newValue) =>
            setCountry(newValue?.alpha2.toUpperCase() ?? "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Nazione"
              inputProps={{ ...params.inputProps }}
            />
          )}
          disabled={isPaying}
        />
      </Grid>
      <Grid item sm={6}></Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Codice Fiscale / Partita IVA"
          value={taxCode}
          onChange={(evt) => setTaxCode(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Numero di Telefono"
          helperText="Opzionale"
          value={phone}
          onChange={(evt) => setPhone(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
    </>
  );

  const receiptFields = (
    <>
      <Grid item xs={12}>
        <ToggleButtonGroup
          color="primary"
          value={donorType}
          exclusive
          onChange={(_, newValue: DonorType | null) =>
            handleDonorType(newValue)
          }
          disabled={isPaying}
        >
          <ToggleButton value={DonorType.Individual}>
            Persona Fisica
          </ToggleButton>
          <ToggleButton value={DonorType.Company}>Azienda</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {donorType === DonorType.Individual
        ? individualFields
        : donorType === DonorType.Company
        ? companyFields
        : null}
    </>
  );

  return (
    <>
      <Typography pt={5} pb={2}>
        Compila tutti i dati qui sotto ed effettua la donazione con carta di
        credito per partecipare alla cena solidale.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="Nome di chi prenota"
            value={bookerName}
            placeholder="Nome e cognome"
            helperText={isAdmin ? "Opzionale" : null}
            onChange={(evt) => setBookerName(evt.currentTarget.value)}
            disabled={isPaying}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="Indirizzo Email (per ricevuta e comunicazioni importanti)"
            value={email}
            helperText={isAdmin ? "Opzionale" : null}
            onChange={(evt) => setEmail(evt.currentTarget.value)}
            type="email"
            disabled={isPaying}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="numberPlaces">
              Numero di Posti
            </InputLabel>
            <NativeSelect
              inputProps={{
                id: "numberPlaces",
              }}
              disabled={isPaying}
              value={numPlaces}
              onChange={(evt) =>
                setNumPlaces(evt.currentTarget.value ?? evt.target.value)
              }
            >
              <option value=""></option>
              {new Array(isAdmin ? 10 : config.dinner.availablePlaces)
                .fill(0)
                .map((_, index) => (
                  <option key={index} value={index + 1}>
                    {index + 1}
                  </option>
                ))}
            </NativeSelect>
          </FormControl>
        </Grid>
        {participantFields}
        <Grid item xs={12}>
          <FormControl fullWidth variant="standard">
            <InputLabel htmlFor="extraDonation">
              Donazione Aggiuntiva (opzionale)
            </InputLabel>
            <Input
              id="extraDonation"
              endAdornment={<InputAdornment position="end">€</InputAdornment>}
              inputProps={{
                inputMode: "numeric",
                pattern: "[0-9]*",
                readOnly: isPaying,
              }}
              value={extraDonation ?? ""}
              onChange={(evt) =>
                setExtraDonation(
                  evt.currentTarget.value.replaceAll(/[^0-9]/g, "")
                )
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="Preferirei sedermi al tavolo con… (opzionale)"
            multiline
            rows={2}
            value={comments}
            disabled={isPaying}
            onChange={(evt) => setComments(evt.currentTarget.value)}
          />
        </Grid>
        {isAdmin ? (
          <Grid item xs={12}>
            <InputLabel>Metodo di Pagamento</InputLabel>
            <ToggleButtonGroup
              color="primary"
              value={paymentMethod}
              exclusive
              onChange={(_, newValue: PaymentMethod | null) =>
                setPaymentMethod(newValue ?? PaymentMethod.Stripe)
              }
              disabled={isPaying}
            >
              <ToggleButton value={PaymentMethod.Stripe}>Carta</ToggleButton>
              <ToggleButton value={PaymentMethod.Cash}>Contanti</ToggleButton>
              <ToggleButton value={PaymentMethod.Bank}>Bonifico</ToggleButton>
              <ToggleButton value={PaymentMethod.Cheque}>Assegno</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        ) : null}
        <Grid item xs={12}>
          <Typography variant="h6">
            <span>Donazione totale: </span>
            <span>
              {new Intl.NumberFormat(navigator.language, {
                style: "currency",
                currency: "EUR",
              }).format(calculatedAmount)}
            </span>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={receipt}
                onChange={toggleReceipt}
                disabled={isPaying}
              />
            }
            label="Desidero una ricevuta della donazione"
          />
        </Grid>
        {receipt ? receiptFields : null}
        <Grid item xs={12} mb={4}>
          {isPaying ? (
            <StripePayment
              formData={bookingData}
              donationMode={DonationMode.Dinner}
            />
          ) : (
            <Stack mt={4} mb={4} spacing={2} direction="row">
              <Button onClick={clearAll}>Cancella Tutto</Button>
              {paymentMethod === PaymentMethod.Stripe ? (
                <Button
                  variant="contained"
                  onClick={() => setIsPaying(true)}
                  disabled={!isFormComplete()}
                >
                  Inserisci i dati della carta
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleManualBooking}
                  disabled={!isFormComplete() || manualBookingLoading}
                >
                  Completa prenotazione
                </Button>
              )}
            </Stack>
          )}
        </Grid>
      </Grid>
      <Snackbar open={!!error} autoHideDuration={6000}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  );
};

export default DinnerBookingForm;
