import { Box } from "@mui/material";
import logo from "../../assets/logo.png";

const Header = () => {
  return (
    <Box padding={2} sx={{ textAlign: "center" }}>
      <a href="https://maishamarefu.org" target="_blank" rel="noreferrer">
        <img
          src={logo}
          alt="Maisha Marefu ETS"
          style={{ maxWidth: "100%", maxHeight: 80 }}
        />
      </a>
    </Box>
  );
};

export default Header;
