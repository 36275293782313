import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  NativeSelect,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useLocation } from "wouter";
import dataManager from "../../model/dataManager";

const DinnerWaitlistForm = () => {
  const [, setLocation] = useLocation();
  const [error, setError] = useState<string | null>(null);
  const [isBooking, setIsBooking] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [numPlaces, setNumPlaces] = useState(0);

  const clearAll = () => {
    setError(null);
    setName("");
    setEmail("");
    setPhone("");
    setNumPlaces(0);
  };

  const handleWaitlist = async () => {
    setIsBooking(true);
    try {
      const id = await dataManager.addToWaitlist({
        name,
        email,
        phone,
        numPlaces,
      });
      setLocation(`/cena/thank-you?id=${id}&type=waitlist`);
    } catch (err) {
      setError(
        (err as Error).message ??
          "Si è verificato un errore inaspettato durante il pagamento"
      );
    }
    setIsBooking(false);
  };

  const isFormComplete = () => {
    if (!name) {
      return false;
    }

    if (!email) {
      return false;
    }

    if (!phone) {
      return false;
    }

    if (numPlaces === 0) {
      return false;
    }

    return true;
  };

  return (
    <>
      <Typography pt={5} pb={2}>
        Siamo spiacenti, ma i posti disponibili sono esauriti.
        <br />
        Ti consigliamo di lasciare i tuoi dati per la lista d'attesa, e ti
        contatteremo nel caso si liberino dei posti.
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="Nome di chi prenota"
            value={name}
            placeholder="Nome e cognome"
            onChange={(evt) => setName(evt.currentTarget.value)}
            disabled={isBooking}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="Indirizzo Email"
            value={email}
            onChange={(evt) => setEmail(evt.currentTarget.value)}
            type="email"
            disabled={isBooking}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="Numero di Telefono"
            value={phone}
            onChange={(evt) => setPhone(evt.currentTarget.value)}
            type="phone"
            disabled={isBooking}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel variant="standard" htmlFor="numberPlaces">
              Numero di Posti
            </InputLabel>
            <NativeSelect
              inputProps={{
                id: "numberPlaces",
              }}
              disabled={isBooking}
              value={numPlaces}
              onChange={(evt) => setNumPlaces(+evt.currentTarget.value)}
            >
              <option value={0}>Scegli quanti posti prenotare&hellip;</option>
              {new Array(10).fill(0).map((_, index) => (
                <option key={index} value={index + 1}>
                  {index + 1}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        </Grid>
        <Grid item xs={12} mb={4}>
          <Stack mt={4} mb={4} spacing={2} direction="row">
            <Button onClick={clearAll}>Cancella Tutto</Button>
            <Button
              variant="contained"
              onClick={handleWaitlist}
              disabled={!isFormComplete() || isBooking}
            >
              Aggiungi alla lista d'attesa
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Snackbar open={!!error} autoHideDuration={6000}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  );
};

export default DinnerWaitlistForm;
