import { atom } from "recoil";
import { ConfigState, State } from "./dataManager";

export const configState = atom<ConfigState>({
  key: "Config",
  default: {
    donations: [],
    status: State.NotReady,
    configuration: {
      recurring: false,
      single: false,
      arbitrary: false,
    },
    dinnerStatus: State.NotReady,
    dinner: {
      open: false,
      date: "",
      time: "",
      amount: 0,
      availablePlaces: 0,
    },
  },
});

export const isPayingState = atom<boolean>({
  key: "IsPaying",
  default: false,
});

export const isAdminState = atom<boolean>({
  key: "IsAdmin",
  default: false,
});
