import { CircularProgress, Container } from "@mui/material";

const Spinner = () => (
  <Container
    maxWidth="lg"
    sx={{
      mt: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <CircularProgress />
  </Container>
);

export default Spinner;
