import countries from "world_countries_lists/data/countries/it/countries.json";
import {
  Alert,
  Autocomplete,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Link,
  Snackbar,
  Stack,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { configState, isPayingState } from "../../model/store";
import { Link as RouterLink, Redirect } from "wouter";
import {
  DonationMode,
  DonorType,
  PaymentMethod,
  getDonationAmount,
  getDonationTypeTitle,
} from "../../model/donationUtils";
import StripePayment from "../../components/StripePayment/StripePayment";
import dataManager from "../../model/dataManager";

const DonationForm = ({ code }: { code: string }) => {
  const config = useRecoilValue(configState);
  const donation = config.donations.find((d) => d.active && d.code === code);
  const donationAmount = useMemo(
    () => (donation ? getDonationAmount(donation) : null),
    [donation]
  );
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod | null>(
    null
  );
  const [bankTransferLoading, setBankTransferLoading] = useState(false);
  const [bankTransferError, setBankTransferError] = useState<string | null>(
    null
  );
  const [bankTransferId, setBankTransferId] = useState<string | null>(null);

  const [isPaying, setIsPaying] = useRecoilState(isPayingState);
  const [amount, setAmount] = useState(donationAmount?.value?.toString() ?? "");
  const [email, setEmail] = useState("");
  const [receipt, setReceipt] = useState(false);
  const [donorType, setDonorType] = useState<DonorType | null>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [city, setCity] = useState("");
  const [postCode, setPostCode] = useState("");
  const [country, setCountry] = useState("");
  const [taxCode, setTaxCode] = useState("");
  const [phone, setPhone] = useState("");
  const [companyName, setCompanyName] = useState("");

  const handlePaymentMethod = useCallback(
    (method: PaymentMethod) => {
      setPaymentMethod(method === paymentMethod ? null : method);
    },
    [paymentMethod]
  );

  if (!donation) {
    return <Redirect to="/" />;
  }

  const donationData = {
    code: donation.code,
    amount: +amount,
    receipt,
    donorType: donorType ?? undefined,
    firstName,
    lastName,
    companyName,
    email,
    phone,
    address: streetAddress,
    city,
    postcode: postCode,
    country,
    taxcode: taxCode,
  };

  const isAmountFixed = isPaying || getDonationAmount(donation).value !== null;
  const isRecurring = donation.type === DonationMode.Recurring;

  const clearAll = () => {
    setDonorType(null);
    setReceipt(false);
    setEmail("");
    if (!isAmountFixed) {
      setAmount("");
    }
    clearForm();
  };

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setStreetAddress("");
    setCity("");
    setPostCode("");
    setCountry("");
    setTaxCode("");
    setPhone("");
    setCompanyName("");
    setPaymentMethod(null);
  };

  const handleDonorType = (value: DonorType | null) => {
    setDonorType(value);
    clearForm();
  };

  const handleBankTransfer = async () => {
    setBankTransferLoading(true);
    setBankTransferError(null);
    try {
      const id = await dataManager.initBankTransfer(donationData);
      setBankTransferId(id);
    } catch (err) {
      setBankTransferError((err as Error).message);
    }
    setBankTransferLoading(false);
  };

  const toggleReceipt = () => {
    setReceipt(!receipt);
    setDonorType(null);
    clearForm();
  };

  const isFormComplete = () => {
    if (!amount) {
      return false;
    }

    if (!paymentMethod) {
      return false;
    }

    if (receipt) {
      if (!donorType) {
        return false;
      }

      if (donorType === DonorType.Individual) {
        if (
          firstName.trim().length === 0 ||
          lastName.trim().length === 0 ||
          streetAddress.trim().length === 0 ||
          city.trim().length === 0 ||
          postCode.trim().length === 0 ||
          country.trim().length === 0 ||
          taxCode.trim().length === 0
        ) {
          return false;
        }
      }
      if (donorType === DonorType.Company) {
        if (
          companyName.trim().length === 0 ||
          streetAddress.trim().length === 0 ||
          city.trim().length === 0 ||
          postCode.trim().length === 0 ||
          country.trim().length === 0 ||
          taxCode.trim().length === 0
        ) {
          return false;
        }
      }
    }

    if (isRecurring && email.trim().length === 0) {
      return false;
    }

    return true;
  };

  const individualFields = (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Nome"
          value={firstName}
          onChange={(evt) => setFirstName(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Cognome"
          value={lastName}
          onChange={(evt) => setLastName(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Indirizzo"
          value={streetAddress}
          onChange={(evt) => setStreetAddress(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          variant="standard"
          label="Città"
          value={city}
          onChange={(evt) => setCity(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          variant="standard"
          label="CAP"
          value={postCode}
          onChange={(evt) => setPostCode(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          fullWidth
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.name}
          value={
            countries.find((c) => c.alpha2 === country.toLowerCase()) ?? null
          }
          onChange={(_, newValue) =>
            setCountry(newValue?.alpha2.toUpperCase() ?? "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Nazione"
              inputProps={{ ...params.inputProps }}
            />
          )}
          disabled={isPaying}
        />
      </Grid>
      <Grid item sm={6}></Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Codice Fiscale"
          value={taxCode}
          onChange={(evt) => setTaxCode(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Numero di Telefono"
          helperText="Opzionale"
          value={phone}
          onChange={(evt) => setPhone(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
    </>
  );

  const companyFields = (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Ragione Sociale"
          value={companyName}
          onChange={(evt) => setCompanyName(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item sm={6}></Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Indirizzo"
          value={streetAddress}
          onChange={(evt) => setStreetAddress(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          variant="standard"
          label="Città"
          value={city}
          onChange={(evt) => setCity(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextField
          fullWidth
          variant="standard"
          label="CAP"
          value={postCode}
          onChange={(evt) => setPostCode(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          fullWidth
          options={countries}
          autoHighlight
          getOptionLabel={(option) => option.name}
          value={
            countries.find((c) => c.alpha2 === country.toLowerCase()) ?? null
          }
          onChange={(_, newValue) =>
            setCountry(newValue?.alpha2.toUpperCase() ?? "")
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Nazione"
              inputProps={{ ...params.inputProps }}
            />
          )}
          disabled={isPaying}
        />
      </Grid>
      <Grid item sm={6}></Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Codice Fiscale / Partita IVA"
          value={taxCode}
          onChange={(evt) => setTaxCode(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          variant="standard"
          label="Numero di Telefono"
          helperText="Opzionale"
          value={phone}
          onChange={(evt) => setPhone(evt.currentTarget.value)}
          disabled={isPaying}
        />
      </Grid>
    </>
  );

  const receiptFields = (
    <>
      <Grid item xs={12}>
        <ToggleButtonGroup
          color="primary"
          value={donorType}
          exclusive
          onChange={(_, newValue: DonorType | null) =>
            handleDonorType(newValue)
          }
          disabled={isPaying}
        >
          <ToggleButton value={DonorType.Individual}>
            Persona Fisica
          </ToggleButton>
          <ToggleButton value={DonorType.Company}>Azienda</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      {donorType === DonorType.Individual
        ? individualFields
        : donorType === DonorType.Company
        ? companyFields
        : null}
    </>
  );

  if (bankTransferId) {
    return (
      <Redirect
        to={`/thank-you?type=${PaymentMethod.BankTransfer}&id=${bankTransferId}`}
      />
    );
  }

  return (
    <>
      <Container>
        <Breadcrumbs sx={{ marginBottom: "1rem" }}>
          <Link underline="hover" color="inherit" component={RouterLink} to="/">
            Home
          </Link>
          <Link
            underline="hover"
            color="inherit"
            component={RouterLink}
            to={`/type/${donation.type}`}
          >
            {getDonationTypeTitle(donation.type)}
          </Link>
          <Typography color="text.primary">{donation.label}</Typography>
        </Breadcrumbs>
        <Typography variant="h4" sx={{ marginBottom: "1rem" }}>
          {getDonationTypeTitle(donation.type)}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="standard"
              label="Donazione"
              value={donation.label}
              inputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth variant="standard">
              <InputLabel htmlFor="amount">Importo</InputLabel>
              <Input
                id="amount"
                endAdornment={<InputAdornment position="end">€</InputAdornment>}
                value={amount ?? ""}
                onChange={(evt) =>
                  !isAmountFixed &&
                  setAmount(evt.currentTarget.value.replaceAll(/[^0-9]/g, ""))
                }
                inputProps={{ readOnly: isAmountFixed }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Indirizzo Email"
              helperText={isRecurring ? null : "Opzionale"}
              variant="standard"
              value={email}
              onChange={(evt) => setEmail(evt.currentTarget.value)}
              disabled={isPaying}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={receipt}
                  onChange={toggleReceipt}
                  disabled={isPaying}
                />
              }
              label="Desidero una ricevuta della donazione"
            />
          </Grid>
          {email.trim().length === 0 && receipt ? (
            <Alert severity="warning" sx={{ mt: 2, ml: 2 }}>
              Attenzione, stai richiedendo una ricevuta senza specificare un
              indirizzo email a cui mandarla. Inserisci l'indirizzo email,
              oppure scrivi a info@maishamarefu.org per richiedere la ricevuta
              una volta effettuata la donazione.
            </Alert>
          ) : null}
          {receipt ? receiptFields : null}
          <Grid item xs={12}>
            <Typography variant="subtitle1" mb={1}>
              Metodo di Pagamento
            </Typography>
            <ButtonGroup disableElevation>
              <Button
                disabled={isPaying}
                variant={
                  paymentMethod === PaymentMethod.Stripe
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handlePaymentMethod(PaymentMethod.Stripe)}
              >
                Carta di Credito
              </Button>
              <Button
                disabled={isPaying}
                variant={
                  paymentMethod === PaymentMethod.BankTransfer
                    ? "contained"
                    : "outlined"
                }
                onClick={() => handlePaymentMethod(PaymentMethod.BankTransfer)}
              >
                Bonifico Bancario
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={12} mb={4}>
            {isPaying ? (
              <StripePayment
                formData={donationData}
                donationMode={donation.type}
              />
            ) : (
              <Stack mt={4} mb={4} spacing={2} direction="row">
                <Button onClick={clearAll}>Cancella Tutto</Button>
                {paymentMethod === PaymentMethod.Stripe ? (
                  <Button
                    variant="contained"
                    onClick={() => setIsPaying(true)}
                    disabled={!isFormComplete()}
                  >
                    Inserisci i dati della carta
                  </Button>
                ) : paymentMethod === PaymentMethod.BankTransfer ? (
                  <Button
                    variant="contained"
                    onClick={handleBankTransfer}
                    disabled={!isFormComplete() || bankTransferLoading}
                  >
                    Ottieni dettagli bonifico
                  </Button>
                ) : null}
              </Stack>
            )}
          </Grid>
        </Grid>
      </Container>
      <Snackbar open={!!bankTransferError} autoHideDuration={6000}>
        <Alert severity="error">{bankTransferError}</Alert>
      </Snackbar>
    </>
  );
};

export default DonationForm;
