import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React, { useCallback } from "react";
import { Link } from "wouter";
import { DonationMode, getDonationTypeTitle } from "../../model/donationUtils";

const DonationType = ({ type }: { type: DonationMode }) => {
  const getDescription = useCallback((type: DonationMode) => {
    if (type === DonationMode.Recurring)
      return "Sostieni una singola attività ogni mese. Garantisci un sostegno costante nel modo che preferisci.";
    if (type === DonationMode.OneOff)
      return "Sostieni ciò per cui abbiamo necessità immediata. Aiutaci nei progetti che hanno più bisogno in questo momento.";
    if (type === DonationMode.Custom)
      return "Sostieni un progetto o una tematica a tua scelta. Scegli come preferisci che venga usata la tua donazione.";
  }, []);

  return (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Link href={`/type/${type}`}>
        <CardActionArea sx={{ flex: "1" }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {getDonationTypeTitle(type)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {getDescription(type)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
      <CardActions>
        <Link href={`/type/${type}`}>
          <Button size="small" color="primary">
            Dona Ora
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export default DonationType;
