import { Alert, Button, Snackbar, Stack } from "@mui/material";
import * as Sentry from "@sentry/react";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeElementsOptions,
  StripePaymentElementOptions,
  loadStripe,
} from "@stripe/stripe-js";
import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { HOSTNAME, STRIPE_KEY } from "../../const";
import dataManager, {
  DinnerPaymentData,
  PaymentData,
} from "../../model/dataManager";
import { DonationMode, PaymentMethod } from "../../model/donationUtils";
import { isPayingState } from "../../model/store";
import Spinner from "../Spinner/Spinner";

const stripePromise = loadStripe(STRIPE_KEY);

const StripeForm = ({
  formData,
  donationMode,
}: {
  formData: PaymentData | DinnerPaymentData;
  donationMode: DonationMode;
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const setIsPaying = useSetRecoilState(isPayingState);

  const handleCancel = () => {
    setIsPaying(false);
  };

  const handlePayment = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);
    setError(null);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setError(submitError.message ?? null);
      setIsLoading(false);
      return;
    }

    try {
      let clientSecret: string, donationId: string;
      if (donationMode !== DonationMode.Dinner) {
        const { token, id } = await dataManager.initPayment(
          formData as PaymentData,
          donationMode === DonationMode.Recurring
        );
        clientSecret = token;
        donationId = id;
      } else {
        const { token, id } = await dataManager.initDinnerPayment(
          formData as DinnerPaymentData
        );
        clientSecret = token;
        donationId = id;
      }
      const { error } = await stripe.confirmPayment({
        elements: elements ?? undefined,
        clientSecret,
        confirmParams: {
          return_url:
            donationMode === DonationMode.Dinner
              ? `${HOSTNAME}/cena/thank-you?type=${PaymentMethod.Stripe}&id=${donationId}`
              : `${HOSTNAME}/thank-you?type=${PaymentMethod.Stripe}&id=${donationId}`,
        },
      });

      if (error) {
        Sentry.captureException(error);
        if (error.type === "card_error" || error.type === "validation_error") {
          setError(error.message ?? null);
        } else {
          setError(
            "Si è verificato un errore inaspettato durante il pagamento"
          );
        }
      }
    } catch (err) {
      Sentry.captureException(error);
      setError(
        (err as Error).message ??
          "Si è verificato un errore inaspettato durante il pagamento"
      );
    }

    setIsLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: "accordion",
      radios: false,
    },
    defaultValues: {
      billingDetails: {
        name: `${formData.firstName} ${formData.lastName}`.trim(),
        email: formData.email,
        phone: formData.phone,
        address: {
          line1: formData.address,
          city: formData.city,
          country: formData.country,
          postal_code: formData.postcode,
        },
      },
    },
  };

  return (
    <>
      <PaymentElement options={paymentElementOptions} />
      {isLoading ? (
        <Spinner />
      ) : (
        <Stack spacing={2} direction="row" sx={{ mt: 3 }}>
          <Button variant="outlined" onClick={handleCancel}>
            Annulla
          </Button>
          <Button
            variant="contained"
            onClick={handlePayment}
            disabled={!stripe}
          >
            Paga Ora
          </Button>
        </Stack>
      )}

      <Snackbar open={!!error} autoHideDuration={6000}>
        <Alert severity="error">{error}</Alert>
      </Snackbar>
    </>
  );
};

const StripePayment = ({
  formData,
  donationMode,
}: {
  formData: PaymentData | DinnerPaymentData;
  donationMode: DonationMode;
}) => {
  const stripeOptions: StripeElementsOptions = {
    mode: donationMode === DonationMode.Recurring ? "subscription" : "payment",
    amount: formData.amount * 100,
    currency: "eur",
    appearance: {
      theme: "stripe",
    },
    locale: "it-IT",
  };

  return (
    <Elements options={stripeOptions} stripe={stripePromise}>
      <StripeForm formData={formData} donationMode={donationMode} />
    </Elements>
  );
};

export default StripePayment;
