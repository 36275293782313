import * as Sentry from "@sentry/react";
import axios, { AxiosError, AxiosResponse } from "axios";
import { DonationMode, DonorType } from "./donationUtils";

export type DonationConfig = {
  code: string;
  amount: number;
  budget: number;
  active: boolean;
  label: string;
  type: DonationMode;
};

type GoogleConfiguration = {
  donations: any[][];
  configuration: {
    recurring: boolean;
    single: boolean;
    arbitrary: boolean;
  };
};

type BackendErrorResponse = AxiosError<{ message: string; code: number }>;

export enum State {
  NotReady,
  Loading,
  Error,
  Success,
}

export type ConfigState = {
  donations: DonationConfig[];
  status: State;
  configuration: {
    recurring: boolean;
    single: boolean;
    arbitrary: boolean;
  };
  dinnerStatus: State;
  dinner: DinnerConfiguration;
};

type BasicPaymentData = {
  amount: number;
  receipt: boolean;
  donorType?: DonorType;
  firstName?: string;
  lastName?: string;
  companyName: string;
  email?: string;
  phone?: string;
  address?: string;
  city?: string;
  postcode?: string;
  country?: string;
  taxcode?: string;
};

export type PaymentData = BasicPaymentData & {
  code: string;
};

export type DinnerPaymentData = BasicPaymentData & {
  name: string;
  email: string;
  places: number;
  participants: string[];
  extraDonation?: number;
  comments?: string;
  paymentMethod: string;
};

type DinnerConfiguration = {
  open: boolean;
  date: string;
  time: string;
  amount: number;
  availablePlaces: number;
};

function rowToDonationConfig(row: any[]): DonationConfig | undefined {
  if (row.length < 6) {
    return undefined;
  }

  return {
    code: row[0],
    amount: row[1],
    budget: row[2],
    active: row[3] === "YES",
    label: row[4],
    type: row[5],
  };
}

class DataManager {
  private donationConfigs: DonationConfig[] = [];
  private recurringEnabled = false;
  private singleEnabled = false;
  private arbitraryEnabled = false;
  private dinnerConfig: DinnerConfiguration = {
    open: false,
    date: "",
    time: "",
    amount: 0,
    availablePlaces: 0,
  };
  private state_: State = State.NotReady;
  private dinnerState_: State = State.NotReady;
  private api = axios.create({
    baseURL: "/api",
  });

  constructor() {
    this.api.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    );
  }

  async refresh() {
    try {
      this.state_ = State.Loading;
      const { data } = await this.api.get<GoogleConfiguration>("/data");
      this.state_ = State.Success;
      this.donationConfigs = data.donations
        .map((d) => rowToDonationConfig(d))
        .filter((c) => c !== undefined) as DonationConfig[];
      this.recurringEnabled = data.configuration.recurring;
      this.arbitraryEnabled = data.configuration.arbitrary;
      this.singleEnabled = data.configuration.single;
    } catch (err) {
      this.state_ = State.Error;
      console.error("Could not refresh data", err);
    }
  }

  async refreshDinner() {
    try {
      this.dinnerState_ = State.Loading;
      const { data } = await this.api.get<DinnerConfiguration>("/dinner/data");
      this.dinnerState_ = State.Success;
      this.dinnerConfig = data;
    } catch (err) {
      this.dinnerState_ = State.Error;
      console.error("Could not refresh data", err);
    }
  }

  get state(): ConfigState {
    return {
      donations: this.donationConfigs,
      status: this.state_,
      configuration: {
        recurring: this.recurringEnabled,
        single: this.singleEnabled,
        arbitrary: this.arbitraryEnabled,
      },
      dinnerStatus: this.dinnerState_,
      dinner: this.dinnerConfig,
    };
  }

  async initPayment(paymentData: PaymentData, recurring: boolean) {
    try {
      const { data } = await this.api.post<{
        token: string;
        id: string;
      }>(recurring ? "/stripe/recurring" : "/stripe/payment", paymentData);
      return {
        token: data.token,
        id: data.id,
      };
    } catch (err) {
      const error = err as BackendErrorResponse;
      throw new Error(error.response?.data.message);
    }
  }

  async initBankTransfer(paymentData: PaymentData) {
    try {
      const { data } = await this.api.post<{ id: string }>(
        "/bank",
        paymentData
      );
      return data.id;
    } catch (err) {
      const error = err as BackendErrorResponse;
      throw new Error(error.response?.data.message);
    }
  }

  async initDinnerPayment(paymentData: DinnerPaymentData) {
    try {
      const { data } = await this.api.post<{
        token: string;
        id: string;
      }>("/stripe/dinner", paymentData);
      return {
        token: data.token,
        id: data.id,
      };
    } catch (err) {
      const error = err as BackendErrorResponse;
      throw new Error(error.response?.data.message);
    }
  }

  async createManualBooking(paymentData: DinnerPaymentData) {
    try {
      const { data } = await this.api.post("/dinner/manual", paymentData);
      return {
        id: data.id,
        type: data.type,
      };
    } catch (err) {
      const error = err as BackendErrorResponse;
      throw new Error(error.response?.data.message);
    }
  }

  async addToWaitlist(waitlistData: {
    name: string;
    email: string;
    phone: string;
    numPlaces: number;
  }) {
    try {
      const { data } = await this.api.post("/dinner/waitlist", waitlistData);
      return {
        id: data.id,
      };
    } catch (err) {
      const error = err as BackendErrorResponse;
      throw new Error(error.response?.data.message);
    }
  }
}

const dataManager = new DataManager();
export default dataManager;
