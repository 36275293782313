import { CircularProgress, Grid } from "@mui/material";
import { useRecoilValue } from "recoil";
import DonationType from "../../components/DonationType/DonationType";
import { State } from "../../model/dataManager";
import { DonationMode } from "../../model/donationUtils";
import { configState } from "../../model/store";

const DonationTypes = () => {
  const config = useRecoilValue(configState);

  if (config.status === State.Loading) {
    return <CircularProgress />;
  }

  const donationTypes: DonationMode[] = [];
  if (config.status === State.Success) {
    if (config.configuration.recurring) {
      donationTypes.push(DonationMode.Recurring);
    }
    if (config.configuration.single) {
      donationTypes.push(DonationMode.OneOff);
    }
    if (config.configuration.arbitrary) {
      donationTypes.push(DonationMode.Custom);
    }
  }

  return (
    <Grid container spacing={2} justifyContent="center">
      {donationTypes.map((type) => (
        <Grid item xs={12} md={4} key={type}>
          <DonationType type={type} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DonationTypes;
