import { Button, Stack, Typography } from "@mui/material";
import { Link, Redirect } from "wouter";
import { PaymentMethod } from "../../model/donationUtils";
import { useLocationProperty } from "wouter/use-location";

const DonationSuccess = () => {
  const type = useLocationProperty(() =>
    new URLSearchParams(window.location.search).get("type")
  );
  const id = useLocationProperty(() =>
    new URLSearchParams(window.location.search).get("id")
  );

  if (!Object.values(PaymentMethod).includes(type as PaymentMethod)) {
    return <Redirect to="/" />;
  }
  const paymentMethod = type as PaymentMethod;

  const redirectToWebsite = () => {
    window.location.href = "https://maishamarefu.org";
  };

  const handleDownloadReceipt = () => {
    if (paymentMethod === PaymentMethod.BankTransfer) {
      window.location.href = `/api/receipt/bank/${id}`;
    }
  };

  const stripe = (
    <>
      <Typography color="text.primary" mt={2}>
        La donazione &egrave; andata a buon fine.
        <br />
        Riferimento donazione: {id}
        <br />
        <br />
        Se hai inserito un indirizzo email, a breve riceverai una conferma della
        donazione. La ricevuta, se richiesta, sar&agrave; allegata all'email.
      </Typography>
      {type === PaymentMethod.BankTransfer && id ? (
        <Button
          variant="outlined"
          sx={{ mt: 2, mb: 4 }}
          onClick={handleDownloadReceipt}
        >
          Scarica ora la ricevuta
        </Button>
      ) : null}
    </>
  );

  const bank = (
    <>
      <Typography color="text.primary" mt={2}>
        Dati ricevuti!
        <br />
        Abbiamo registrato il progetto che vuoi sostenere, ora devi soltanto
        effettuare il bonifico con i dati seguenti.
        <br />
        <br />
        Denominazione: Maisha Marefu ETS
        <br />
        IBAN: IT06 N030 6909 6061 0000 0102 049
        <br />
        BIC/SWIFT: BCITITMX
        <br />
        Causale: Donazione Solidale {id}
        <br />
        <br />
        Se hai inserito un indirizzo email, a breve riceverai una conferma della
        donazione, inclusa una copia dei dati del bonifico allegata.
        <Button
          variant="outlined"
          sx={{ mt: 2, mb: 4 }}
          onClick={handleDownloadReceipt}
        >
          Scarica una copia delle coordinate bancarie
        </Button>
      </Typography>
    </>
  );

  return (
    <>
      <Typography variant="h4">Grazie!</Typography>
      {paymentMethod === PaymentMethod.Stripe
        ? stripe
        : paymentMethod === PaymentMethod.BankTransfer
        ? bank
        : null}
      <Stack spacing={2} direction="row" mt={4}>
        <Button variant="outlined" onClick={redirectToWebsite}>
          Torna al Sito
        </Button>
        <Link to="/">
          <Button variant="contained">Nuova Donazione</Button>
        </Link>
      </Stack>
    </>
  );
};

export default DonationSuccess;
