import {
  Breadcrumbs,
  Container,
  Link,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import React from "react";
import { useRecoilValue } from "recoil";
import { Redirect, Link as RouterLink } from "wouter";
import DonationListItem from "../../components/DonationListItem/DonationListItem";
import { DonationMode, getDonationTypeTitle } from "../../model/donationUtils";
import { configState } from "../../model/store";

const DonationList = ({ expectedType }: { expectedType: string }) => {
  const config = useRecoilValue(configState);

  if (!Object.values(DonationMode).includes(expectedType as DonationMode)) {
    return <Redirect to="/" />;
  }
  const type = expectedType as DonationMode;
  const relevantDonationData = config.donations.filter(
    (d) => d.active && d.type === type
  );

  return (
    <Container>
      <Breadcrumbs sx={{ marginBottom: "1rem" }}>
        <Link underline="hover" color="inherit" component={RouterLink} to="/">
          Home
        </Link>
        <Typography color="text.primary">
          {getDonationTypeTitle(type)}
        </Typography>
      </Breadcrumbs>
      <Typography variant="h4">{getDonationTypeTitle(type)}</Typography>
      <Typography variant="subtitle1" sx={{ marginBottom: "1rem" }}>
        Scegli che cosa desideri supportare:
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {relevantDonationData.map((donation) => (
              <DonationListItem donation={donation} key={donation.code} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default DonationList;
