import { TableCell, TableRow } from "@mui/material";
import React from "react";
import { Link } from "wouter";
import { DonationConfig } from "../../model/dataManager";
import { getDonationAmount } from "../../model/donationUtils";

const DonationListItem = ({ donation }: { donation: DonationConfig }) => {
  return (
    <Link href={`/dona/${donation.code}`}>
      <TableRow key={donation.code} hover sx={{ cursor: "pointer" }}>
        <TableCell component="th" scope="row">
          {donation.label}
        </TableCell>
        <TableCell align="right">{getDonationAmount(donation).label}</TableCell>
      </TableRow>
    </Link>
  );
};

export default DonationListItem;
