import { Button, Stack, Typography } from "@mui/material";
import { Link } from "wouter";
import { useLocationProperty } from "wouter/use-location";
import { PaymentMethod } from "../../model/donationUtils";

const DinnerSuccess = () => {
  const type = useLocationProperty(() =>
    new URLSearchParams(window.location.search).get("type")
  );
  const id = useLocationProperty(() =>
    new URLSearchParams(window.location.search).get("id")
  );

  const paymentMethod = type as PaymentMethod;

  const redirectToWebsite = () => {
    window.location.href = "https://maishamarefu.org";
  };

  const stripe = (
    <>
      <Typography color="text.primary" mt={2}>
        La prenotazione &egrave; andata a buon fine.
        <br />
        Riferimento prenotazione: {id}
        <br />
        <br />A breve riceverai un'email di conferma. Grazie!
      </Typography>
    </>
  );

  return (
    <>
      <Typography variant="h4">Grazie!</Typography>
      {paymentMethod === PaymentMethod.Stripe ? stripe : null}
      <Stack spacing={2} direction="row" mt={4}>
        <Button variant="outlined" onClick={redirectToWebsite}>
          Torna al Sito
        </Button>
        <Link to="/cena">
          <Button variant="contained">Nuova Prenotazione</Button>
        </Link>
      </Stack>
    </>
  );
};

export default DinnerSuccess;
