import { Alert, Snackbar, Typography } from "@mui/material";
import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import Spinner from "../../components/Spinner/Spinner";
import dataManager, { State } from "../../model/dataManager";
import { configState, isAdminState } from "../../model/store";
import DinnerBookingForm from "../DinnerBookingForm/DinnerBookingForm";
import DinnerWaitlistForm from "../DinnerWaitlistForm/DinnerWaitlistForm";

const Dinner = () => {
  const [config, setConfig] = useRecoilState(configState);
  const [isAdmin, setIsAdmin] = useRecoilState(isAdminState);

  useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    setIsAdmin(params.get("admin") === "true");
  }, [setIsAdmin]);

  useEffect(() => {
    dataManager.refreshDinner().then(() => {
      setConfig(dataManager.state);
    });
  }, [setConfig]);

  const handleClose = useCallback(() => {
    setConfig({
      ...config,
      dinnerStatus: State.NotReady,
    });
  }, [config, setConfig]);

  if (config.dinnerStatus === State.Error) {
    return (
      <Snackbar
        open={config.dinnerStatus === State.Error}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="error" onClose={handleClose}>
          Si è verificato un errore, riprova più tardi
        </Alert>
      </Snackbar>
    );
  }

  if (
    config.dinnerStatus === State.NotReady ||
    config.dinnerStatus === State.Loading
  ) {
    return <Spinner />;
  }

  if (!config.dinner.open) {
    return (
      <Typography variant="h4" textAlign="center" p={5}>
        Le prenotazioni sono chiuse.
        <br />
        Vi preghiamo di riprovare più tardi.
      </Typography>
    );
  }

  return (
    <>
      <Typography variant="h4">Prenotazione Cena Solidale</Typography>
      <Typography>
        {config.dinner.date}, ore {config.dinner.time}
        <br />
        {new Intl.NumberFormat(navigator.language, {
          style: "currency",
          currency: "EUR",
          maximumFractionDigits: 0,
        }).format(config.dinner.amount)}{" "}
        in solidarietà
        <br />
        Nella prestigiosa location presso <b>La Pista</b>
        <br />
        Centro Commerciale <b>Il Centro</b>
        <br />
        Uscita Arese, Autostrada Milano - Laghi
      </Typography>
      {!isAdmin && config.dinner.availablePlaces === 0 ? (
        <DinnerWaitlistForm />
      ) : (
        <DinnerBookingForm />
      )}
    </>
  );
};

export default Dinner;
