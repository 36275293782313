import { Alert, Snackbar } from "@mui/material";
import { ReactNode, useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import dataManager, { State } from "../../model/dataManager";
import { configState } from "../../model/store";
import Spinner from "../Spinner/Spinner";

const DataHandler = ({ children }: { children?: ReactNode }) => {
  const [config, setConfig] = useRecoilState(configState);

  useEffect(() => {
    dataManager.refresh().then(() => {
      setConfig(dataManager.state);
    });
  }, [setConfig]);

  const handleClose = useCallback(() => {
    setConfig({
      ...config,
      status: State.NotReady,
    });
  }, [config, setConfig]);

  if (config.status === State.Error) {
    return (
      <Snackbar
        open={config.status === State.Error}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert severity="error" onClose={handleClose}>
          Si è verificato un errore, riprova più tardi
        </Alert>
      </Snackbar>
    );
  }

  if (config.status === State.NotReady || config.status === State.Loading) {
    return <Spinner />;
  }

  return <>{children}</>;
};

export default DataHandler;
